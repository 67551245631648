import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				ExpertPathway
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:title"} content={"ExpertPathway"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				ExpertPathway
			</Override>
		</Components.Header>
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Відповіді на поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							Як доглядати за велосипедом?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Регулярно перевіряйте тиск у шинах.
						<br />
						Чистіть ланцюг та змащуйте його спеціальними мастилами.
						<br />
						Зберігайте велосипед у сухому місці, щоб уникнути корозії.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Як уникнути поломок мотоцикла?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Змінюйте мастило кожні 5000 км пробігу або за рекомендацією виробника.
						<br />
						Перевіряйте рівень гальмівної рідини.
						<br />
						Слідкуйте за станом акумулятора, особливо в зимовий період.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи потрібно записуватися заздалегідь, або можна прийти без попереднього запису?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Ми рекомендуємо записуватися заздалегідь, щоб уникнути черг та забезпечити зручний час для ремонту. Проте, якщо у вас термінова ситуація, ми можемо прийняти вас без попереднього запису, залежно від наявності вільних майстрів.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Які деталі використовуються при ремонті?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Ми використовуємо лише сертифіковані та якісні деталі для ремонту велосипедів і мотоциклів. Ви можете бути впевнені, що всі комплектуючі, які ми використовуємо, відповідають стандартам безпеки та довговічності.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Як довго триває ремонт?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Час ремонту залежить від складності проблеми. Простий ремонт, наприклад, заміна шини або налаштування гальм, може зайняти 1-2 години. Складніші роботи, такі як діагностика та ремонт двигуна мотоцикла, можуть зайняти кілька днів. Ми надаємо точні терміни після огляду вашого транспорту.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи надається гарантія на ремонт?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Так, ми надаємо гарантію на всі виконані роботи та замінені деталі. Якщо проблема повторюється після ремонту, ми виправимо її безкоштовно в межах гарантійного терміну.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta2 />
		<Components.Footer>
			<Override slot="link5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});